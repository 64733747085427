<template>
  <div>
    <div v-if="data.value === ''"></div>
    <div v-else-if="data.item.nivel === 1" class="text-black text-md font-weight-bold" v-b-tooltip.hover :title="data.value.nombre">{{ data.value.clave }}</div>
    <div v-else-if="data.item.nivel === 2" class="text-black-75 text-md font-weight-bold" v-b-tooltip.hover :title="data.value.nombre">{{ data.value.clave }}</div>
    <div v-else-if="data.item.nivel === 3" class="text-black-75 text-sm font-weight-bold" v-b-tooltip.hover :title="data.value.nombre">{{ data.value.clave }}</div>
    <div v-else-if="data.item.nivel === 4" class="text-black-50 text-sm" v-b-tooltip.hover :title="data.value.nombre">{{ data.value.clave }}</div>
  </div>
</template>

<script>
export default {
  name: 'ReporteEstadoPptoEgresosRow',

  props: {
    data: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
