<template>
  <div>
    <!--x-select-periodo-fiscal class="mb-3" @input="onChangePeriodoFiscal" modulo="Reportes por saldos"/-->
    <b-form @submit="onSubmit" v-if="show">
      <b-row>
        <b-col cols="12" md="3">
          <x-form-group title="Período Fiscal">
            <x-select-periodo-fiscal class="mb-3" @input="onChangePeriodoFiscal" modulo="Reporte Estado Ppto Egresos"/>
          </x-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <x-form-group title="Fecha de inicio">
            <b-form-datepicker
              id="form-fecha-inicial"
              v-model="fecha_inicio"
              class="mb-2"
              placeholder="Fecha de inicio"
              required
            ></b-form-datepicker>
          </x-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <x-form-group title="Fecha de corte">
            <b-form-datepicker
              id="form-fecha-corte"
              v-model="fecha_corte"
              class="mb-2"
              placeholder="Fecha de corte"
              required
            ></b-form-datepicker>
          </x-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <x-form-group title="Módulo">
            <b-form-select v-model="modulo" :options="modulos"></b-form-select>
          </x-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <x-form-group title="Capítulo">
            <x-select
              ref="xselectcapitulos"
              v-model="capitulo_id"
              :dispatchPath="null"
              getterListPath="capitulosModule/getList"
              prepend-key
              :formatListWith="['id', 'clave', 'nombre']"
              :disabled="isLoading || !isCapitulosAvailable"
              no-validate
              no-prepend
              defaultText="-- TODOS --"
            />
          </x-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <x-form-group title="Partida">
            <x-select
              ref="xselectpartida"
              v-model="partida_id"
              :dispatchPath="null"
              getterListPath="partidaModule/getPartidas"
              prepend-key
              :formatListWith="['id', 'clave', 'nombre']"
              :disabled="isLoading || !isPartidasAvailable"
              no-validate
              no-prepend
              defaultText="-- TODAS --"
            />
          </x-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <x-form-group title="Fuente de financiamiento">
            <x-select
              ref="xselectfuentesfinanciamiento"
              v-model="fuente_financiamiento_id"
              :dispatchPath="null"
              getterListPath="fuenteFinanciamientoModule/getList"
              prepend-key
              :formatListWith="['id', 'clave', 'nombre']"
              :disabled="isLoading || !isFuentesFinanciamientoAvailable"
              no-validate
              no-prepend
              defaultText="-- TODAS --"
            />
          </x-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <x-form-group title="Proyecto">
            <x-select
              ref="xselectproyectos"
              v-model="proyecto_id"
              :dispatchPath="null"
              getterListPath="proyectoModule/getList"
              prepend-key
              :formatListWith="['id', 'clave', 'nombre']"
              :disabled="isLoading || !isProyectosAvailable"
              no-validate
              no-prepend
              defaultText="-- TODOS --"
            />
          </x-form-group>
        </b-col>
      </b-row>

      <x-form-group title="Unidad Administrativa">
        <x-select
          ref="xselectunidadesadministrativas"
          v-model="unidad_administrativa_id"
          :dispatchPath="null"
          getterListPath="administrativeUnitModule/getAdministrativeUnits"
          prepend-key
          :formatListWith="['id', 'clave', 'nombre']"
          :disabled="isLoading || !isUnidadesAdministrativasAvailable"
          no-validate
          no-prepend
          defaultText="-- TODAS --"
        />
      </x-form-group>

      <b-form-group>
        <div class="w-100 d-flex justify-content-between">
          <b-button type="submit" size="sm" :disabled="loadingSelects || loading" variant="primary">Consultar</b-button>
          <b-button :disabled="loadingSelects || loading" @click="onExport()" size="sm" variant="success">Exportar</b-button>
        </div>
      </b-form-group>
    </b-form>

    <confirm-selection-dialog v-show="showModal" @on-submitted="submitted"/>

    <b-form v-if="getReporte.length > 0 && getClaves.length > 1 && !loading">
      <b-row>
        <b-col cols="12" md="9">
          <x-form-group :title="`Navegación (${getClaves.length} clave${getClaves.length===1?'':'s'})`">
            <b-pagination
              v-model="currentPage"
              per-page="1"
              :total-rows="rows"
              aria-controls="my-table"
              align="fill"
              @change="selectedPageChange"
            />
          </x-form-group>
        </b-col>

        <b-col cols="12" md="3">
          <x-form-group title="Consulte una clave">
            <v-select
              v-model="currentClave"
              :options="paginated"
              :filterable="false"
              :clearable="false"
              @search="onSearch"
              @option:selected="selectedClaveChange">
              <li slot="list-footer" class="pagination">
                <button :disabled="!hasPrevPage" @click.prevent="offset -= limit">Prev</button>
                <button :disabled="!hasNextPage" @click.prevent="offset += limit">Next</button>
              </li>
            </v-select>
          </x-form-group>
        </b-col>
      </b-row>
    </b-form>

    <div class="card card-header-actions">
      <div class="card-header">
        Reporte
      </div>
      <div class="card">
        <b-overlay :show="loading">
          <template #overlay>
            <loading />
          </template>

          <b-table
            :fields="fields"
            :items="getPageReporte"
            striped
            bordered
            responsive
            hover
            stacked="lg"
            show-empty
            :tbody-tr-class="rowClass"
          >
            <template #cell(fuente_financiamiento)="data">
              <reporte-estado-ppto-egresos-row :data="data"/>
            </template>
            <template #cell(proyecto)="data">
              <reporte-estado-ppto-egresos-row :data="data"/>
            </template>
            <template #cell(unidad_administrativa)="data">
              <reporte-estado-ppto-egresos-row :data="data"/>
            </template>
            <template #cell(partida)="data">
              <reporte-estado-ppto-egresos-row :data="data"/>
            </template>
            <template #cell(tipo_gasto)="data">
              <reporte-estado-ppto-egresos-row :data="data"/>
            </template>
            <template #cell(importes.aprobado)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
            <template #cell(importes.ampliaciones)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
            <template #cell(importes.reducciones)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
            <template #cell(importes.modificado)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
            <template #cell(importes.vigente)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
            <template #cell(importes.comprometido)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
            <template #cell(importes.disponible)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
            <template #cell(importes.devengado)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
            <template #cell(importes.no_devengado)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
            <template #cell(importes.sin_devengar)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
            <template #cell(importes.ejercido)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
            <template #cell(importes.pagado)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
            <template #cell(importes.por_pagar)="data">
              <reporte-estado-ppto-egresos-currency-row :data="data"/>
            </template>
          </b-table>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ConfirmSelectionDialog from '../ConfirmSelectionDialog'
import ReporteEstadoPptoEgresosRow from './ReporteEstadoPptoEgresosRow'
import ReporteEstadoPptoEgresosCurrencyRow from './ReporteEstadoPptoEgresosCurrencyRow'

export default {
  name: 'ReporteEstadoPptoEgresos',

  components: {
    ConfirmSelectionDialog,
    ReporteEstadoPptoEgresosRow,
    ReporteEstadoPptoEgresosCurrencyRow
  },

  mounted () {
    // this.loadCatalogos()
  },

  data () {
    return {
      search: '',
      offset: 0,
      limit: 10,
      reset: false,

      loading: false,

      fields: [
        { key: 'fuente_financiamiento', label: 'Fuente de Financiamiento' },
        { key: 'proyecto', label: 'Proyecto' },
        { key: 'unidad_administrativa', label: 'Unidad Administrativa' },
        { key: 'partida', label: 'Partida' },
        { key: 'tipo_gasto', label: 'Tipo de Gasto' },
        // Importes
        { key: 'importes.aprobado', label: 'Aprobado' },
        { key: 'importes.ampliaciones', label: 'Ampliaciones' },
        { key: 'importes.reducciones', label: 'Reducciones' },
        { key: 'importes.modificado', label: 'Modificado' },
        { key: 'importes.vigente', label: 'Presupuesto Vigente' },
        { key: 'importes.comprometido', label: 'Comprometido' },
        { key: 'importes.disponible', label: 'Disponible para Comprometer' },
        { key: 'importes.devengado', label: 'Devengado' },
        { key: 'importes.no_devengado', label: 'Comprometido no Devengado' },
        { key: 'importes.sin_devengar', label: 'Presupuesto sin Devengar' },
        { key: 'importes.ejercido', label: 'Ejercido' },
        { key: 'importes.pagado', label: 'Pagado' },
        { key: 'importes.por_pagar', label: 'Cuentas por Pagar' }
      ],

      modulos: [
        { value: null, text: '-- TODOS --' },
        { value: 'RH', text: 'Recursos Humanos' },
        { value: 'RMS', text: 'Recursos Materiales' },
        { value: 'SA', text: 'Servicios Administrativos' },
        { value: 'SG', text: 'Servicios Generales' },
        { value: 'F', text: 'Dirección de Contabilidad' },
        { value: 'A', text: 'Dirección Gral. de Administración' },
        { value: 'IE', text: 'Dirección de Ingresos y Egresos' }
      ],

      showModal: false,

      isLoading: false,

      show: true
    }
  },

  computed: {
    filtered () {
      return this.getClaves.filter((clave) =>
        clave.toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
      )
    },
    paginated () {
      return this.filtered.slice(this.offset, this.limit + this.offset)
    },
    hasNextPage () {
      const nextOffset = this.offset + this.limit
      return Boolean(
        this.filtered.slice(nextOffset, this.limit + nextOffset).length
      )
    },
    hasPrevPage () {
      const prevOffset = this.offset - this.limit
      return Boolean(
        this.filtered.slice(prevOffset, this.limit + prevOffset).length
      )
    },
    ...mapGetters('reporteEstadoPptoEgresosModule', [
      'getReporte', 'getClaves', 'getParams', 'getCurrentClave', 'getCurrentPage'
    ]),
    periodo_fiscal_id: {
      get () {
        return this.getParams.periodo_fiscal_id
      },
      set (value) {
        this.setPeriodoFiscalId(value)
      }
    },
    fecha_inicio: {
      get () {
        return this.getParams.fecha_inicio
      },
      set (value) {
        console.log(value)
        this.setFechaInicio(value)
      }
    },
    fecha_corte: {
      get () {
        return this.getParams.fecha_corte
      },
      set (value) {
        this.setFechaCorte(value)
      }
    },
    modulo: {
      get () {
        return this.getParams.modulo
      },
      set (value) {
        this.setModulo(value)
      }
    },
    capitulo_id: {
      get () {
        return this.getParams.capitulo_id
      },
      set (value) {
        this.setCapituloId(value)
      }
    },
    fuente_financiamiento_id: {
      get () {
        return this.getParams.fuente_financiamiento_id
      },
      set (value) {
        this.setFuenteFinanciamientoId(value)
      }
    },
    proyecto_id: {
      get () {
        return this.getParams.proyecto_id
      },
      set (value) {
        this.setProyectoId(value)
      }
    },
    unidad_administrativa_id: {
      get () {
        return this.getParams.unidad_administrativa_id
      },
      set (value) {
        this.setUnidadAdministrativaId(value)
      }
    },
    partida_id: {
      get () {
        return this.getParams.partida_id
      },
      set (value) {
        this.setPartidaId(value)
      }
    },
    currentClave: {
      get () {
        return this.getCurrentClave
      },
      set (value) {
        this.setCurrentClave(value)
      }
    },
    currentPage: {
      get () {
        return this.getCurrentPage
      },
      set (value) {
        this.setCurrentPage(value)
      }
    },

    getPageReporte () {
      return this.getReporte.filter(item => item.clave === this.currentClave)
    },

    rows () {
      return this.getClaves.length
    },

    ...mapGetters('fiscalPeriodsModule', [
      'getAllFiscalPeriods'
    ]),

    ...mapGetters({
      isPeriodosAvailable: 'fiscalPeriodsModule/getAvailableList',
      isCapitulosAvailable: 'capitulosModule/isListAvailable',
      isFuentesFinanciamientoAvailable: 'fuenteFinanciamientoModule/isListAvailable',
      isProyectosAvailable: 'proyectoModule/getAvailableList',
      isUnidadesAdministrativasAvailable: 'administrativeUnitModule/hasAdministrativeUnits',
      isPartidasAvailable: 'partidaModule/getAvailableList'
    }),

    loadingSelects () {
      return !this.isCapitulosAvailable ||
        !this.isFuentesFinanciamientoAvailable ||
        !this.isProyectosAvailable ||
        !this.isUnidadesAdministrativasAvailable ||
        !this.isPartidasAvailable
    }
  },

  methods: {
    ...mapMutations('reporteEstadoPptoEgresosModule', [
      'setPeriodoFiscalId',
      'setModulo',
      'setFechaInicio',
      'setFechaCorte',
      'setCapituloId',
      'setFuenteFinanciamientoId',
      'setProyectoId',
      'setUnidadAdministrativaId',
      'setPartidaId',
      'setCurrentClave',
      'setCurrentPage'
    ]),
    onSearch (query) {
      this.search = query
      this.offset = 0
    },
    selectedClaveChange (value) {
      this.currentPage = this.getClaves.findIndex(row => row === value) + 1
    },

    selectedPageChange (page) {
      this.currentClave = this.getClaves[page - 1]
    },

    loadCatalogo (periodo_id, isAvailable, dispatchRoute, title) {
      if (!isAvailable) {
        this.$store.dispatch(
          dispatchRoute,
          periodo_id
        ).then(result => {
          if (result.error) this.$notify(result, title)
        })
      }
    },

    async loadCatalogos () {
      // Obtenemos período fiscal activo
      // const periodo = await this.$getPeriodoFiscalActivo()
      const periodo = {
        id: 1
      }

      // Capitulos
      this.loadCatalogo(periodo.id, this.isCapitulosAvailable, 'capitulosModule/getAll', 'Capítulos')

      // Fuentes de Financiamiento
      this.loadCatalogo(periodo.id, this.isFuentesFinanciamientoAvailable,
        'fuenteFinanciamientoModule/getAll', 'Fuentes de Financiamiento')

      // Proyectos
      this.loadCatalogo(periodo.id, this.isProyectosAvailable,
        'proyectoModule/getProyectos', 'Proyectos')

      // Unidades Administrativas
      this.loadCatalogo(periodo.id, this.isUnidadesAdministrativasAvailable,
        'administrativeUnitModule/getAdministrativeUnits', 'Unidades Administrativas')

      // Partidas
      this.loadCatalogo(periodo.id, this.isPartidasAvailable,
        'partidaModule/getAll', 'Partidas')

      // Fecha de corte
      const today = new Date()
      const month = (today.getMonth() + 1).toString().padStart(2, '0')
      const day = (today.getDay() + 1).toString().padStart(2, '0')
      this.fecha_corte = today.getFullYear() + '-' + month + '-' + day

      // Fecha de inicio
      console.log(today.getFullYear() + '-' + '01' + '-' + '01')
      this.fecha_inicio = today.getFullYear() + '-' + '01' + '-' + '01'
    },

    async onChangePeriodoFiscal (periodo_fiscal_id) {
      this.periodo_fiscal_id = periodo_fiscal_id

      this.loadCatalogos()

      // if (error) return this.$notify({ error, message }, 'Error')
    },

    async onSubmit (event) {
      event.preventDefault()

      // Verificamos parámetros de la consulta
      if ((!this.fuente_financiamiento_id) && (!this.proyecto_id) && (!this.unidad_administrativa_id) &&
        (!this.partida_id) && (!this.capitulo_id)) {
        // this.$notify({ error: true, message: 'Debe especificar los parámetros de la consulta' }, 'Consulta')
        this.$bvModal.show('ConfirmSelectionDialog')
        return
      }

      this.loading = true
      const { error, message } = await this.$store.dispatch('reporteEstadoPptoEgresosModule/getReporteEstadoPptoEgresos', this.getParams)
      this.loading = false
      this.currentPage = 1
      this.currentClave = this.getClaves[0]

      if (error) return this.$notify({ error, message }, 'Error')
    },

    async onExport () {
      this.loading = true
      const getval = await this.$store.dispatch('reporteEstadoPptoEgresosModule/exportReporteEstadoPptoEgresos', this.getParams)
      this.loading = false
      if (!getval.error) {
        this.$onDownload(
          getval,
          'reporte_estado_ejercicio_ppto_egresos.xlsx'
        )
      } else {
        this.$notify(getval, 'Exportación')
      }
    },

    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.nivel === 1) return 'table-black'
      if (item.nivel === 2) return 'table-active'
      if (item.nivel === 3) return 'table-white'
      if (item.nivel === 4) return 'table-white'
    },

    async submitted () {
      this.$bvModal.hide('ConfirmSelectionDialog')
      // Realizamos la operación
      this.loading = true
      const { error, message } = await this.$store.dispatch('reporteEstadoPptoEgresosModule/getReporteEstadoPptoEgresos', this.getParams)
      this.loading = false
      this.currentPage = 1
      this.currentClave = this.getClaves[0]

      if (error) return this.$notify({ error, message }, 'Error')
    }
  }
}
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}
</style>
